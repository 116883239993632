export const API_ENDPOINT = {
  AUTH: {
    LOGIN: 'api/auth/login',
    PROFILE: 'api/auth/profile',
  },
  MEMBER: {
    LIST_BY_PROJECT: 'api/members/project/:id',
  },
  EMAIL: {
    VERIFY: 'api/sender/verify',
    CHECK_TOKEN: 'api/sender/check-token',
  },
  USER: {
    REGISTER: 'api/users/register',
    LIST: 'api/users',
    DELETED: 'api/users/deleted',
    APPROVE: 'api/users/:id/approve',
    DELETE: 'api/users/:id/delete',
    FORCE_DELETE: 'api/users/:id/delete/force',
    RESTORE: 'api/users/:id/restore',
    UPDATE: 'api/users/:id/edit',
    CHANGE_PERMISSION: 'api/users/:id/permission',
    UPLOAD: 'api/upload/profile',
    FORGOT_PASSWORD: 'api/users/forgot-password',
    CHANGE_PASSWORD: 'api/sender/forgot-password',
  },
  NOTE: {
    LIST: 'api/note',
    CREATE: 'api/note',
    GET: 'api/note/:id',
    UPDATE: 'api/note/:id',
    DELETE: 'api/note/:id',
    UPLOAD: 'api/upload/note/:id',
  },
  FILE: {
    REMOVE: 'api/upload/:id',
    GET_BY_USER: 'api/upload/user',
  },
  PROJECT: {
    CREATE: 'api/project',
    LIST: 'api/project/user',
    GET: 'api/project/:id',
    UPDATE: 'api/project/:id',
    DELETE: 'api/project/:id',
  },
  TODO: {
    LIST: 'api/todo/project/:id',
    LIST_USER: 'api/todo/user',
    LAST_LIST_USER: 'api/todo/user/:total/last',
    UPDATE_STATUS: 'api/todo/:id/status',
    UPDATE_POSITION: 'api/todo/:id/position',
    UPDATE: 'api/todo/:id',
    DELETE: 'api/todo/:id',
    CREATE: 'api/todo/project/:id',
    GET: 'api/todo/:id',
    UPLOAD: 'api/upload/todo/:id',
    UPDATE_DUE_DATE: 'api/todo/:id/due-date',
    UPDATE_NOTIFICATION: 'api/todo/:id/notification',
    UPDATE_DONE: 'api/todo/:id/done',
    UPDATE_ASSIGNEE: 'api/todo/:id/assignee',
    COMMENT: 'api/comments/todo/:id',
    COMMENT_DELETE: 'api/comments/:id',
  },
  TAG: {
    CREATE: 'api/tags',
    UPDATE: 'api/tags/:id',
    GET: 'api/tags/:id',
    LIST: 'api/tags',
    DELETE: 'api/tags/:id',
  },
  SETTING: {
    UPDATE: 'api/setting',
  },
};
